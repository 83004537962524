import { Palette, createTheme } from '@mui/material/styles'

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    component: string
    tab: string
    actionButtons: string
  }

  interface TypeText {
    default: string
    light: string
    disabled: string
  }

  interface Palette {
    na: Palette['primary']
    high: Palette['primary']
    internal: Palette['primary']
    external: Palette['primary']
  }

  interface PaletteOptions {
    na?: PaletteOptions['primary']
    high?: PaletteOptions['primary']
    internal?: PaletteOptions['primary']
    external?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    na: true
    high: true
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    na: true
    high: true
  }
}
declare module '@mui/material/styles' {
  interface TypographyVariants {
    drawerLabel: React.CSSProperties
    drawerValue: React.CSSProperties
    remarkItemTitle: React.CSSProperties
    remarkItemSubTitle: React.CSSProperties
  }
  interface TypographyOptions {
    drawerLabel?: React.CSSProperties
    drawerValue?: React.CSSProperties
    remarkItemTitle?: React.CSSProperties
    remarkItemSubTitle?: React.CSSProperties
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    drawerLabel: true
    drawerValue: true
    remarkItemTitle: true
    remarkItemSubTitle: true
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#244ed9',
    },
    secondary: {
      main: '#0c1c31',
      dark: '#2a2e87',
    },
    background: {
      default: '#0C233C',
      paper: '#FFFFFF',
      component: '#0C1C31',
      tab: '#36404F',
      actionButtons: '#E9EEF4',
    },
    error: {
      main: '#E25151',
    },
    text: {
      default: '#FFFFFF',
      light: '#434E61',
      disabled: '#808080',
      secondary: '#BDE3F1',
    },
    na: {
      main: '#929292',
    },
    high: {
      main: '#FF873D',
    },
    success: {
      main: '#61A34F',
    },
    warning: {
      main: '#FFB356',
    },
    internal: {
      main: '#059ED9',
    },
    external: {
      main: '#7ECDEA',
    }
  },
  typography: (palette: Palette) => ({
    fontFamily: 'Inter, sans-serif',
    fontSize: 14,
    color: palette.text.default,
    h1: {
      fontSize: '36px',
      lineHeight: '44px',
      fontWeight: 700,
    },
    h2: {
      fontSize: '24px',
      lineHeight: '29px',
      fontWeight: 700,
    },
    h3: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    h4: {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 400,
    },
    h5: {
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: 400,
    },
    h6: {
      fontSize: '12px',
      lineHeight: '15px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '14px',
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: '22px',
      lineHeight: '27px',
      fontWeight: 700,
    },
    body2: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '19px',
    },
    drawerLabel: {
      color: '#C7D5E8',
      fontSize: '14px',
      fontWeight: '600',
    },
    drawerValue: {
      color: '#FFFFFF',
      fontSize: '16px',
      fontWeight: '400',
    },
    remarkItemTitle: {
      color: 'rgba(199, 213, 232, 1)',
      fontSize: '12px',
      fontWeight: '400',
    },
    remarkItemSubTitle: {
      color: 'rgba(255, 255, 255, 1)',
      fontSize: '14px',
      fontWeight: '400',
    },
  }),
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 145,
          borderRadius: '0.25rem',
          textTransform: 'none',
          color: '#FFFFFF',
          '&.Mui-disabled': {
            backgroundColor: '#E9EEF4',
            color: '#808080',
          },
        },
        contained: {
          color: '#FFFFFF',
        },
        outlined: {
          borderColor: '#244ED9',
          color: '#808080',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: '#FFF',
          '& .MuiPickersDay-root': {
            color: '#FFF',
          },
          '& .MuiPickersArrowSwitcher-button': {
            color: '#FFF',
          },
          '& .MuiIconButton-edgeEnd': {
            color: '#FFF',
          },
          '& .MuiPickersCalendarHeader-switchViewIcon': {
            color: '#FFFFFF',
          },
          '& .MuiTablePagination-menuItem': {
            color: '#808080',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          '&.Mui-checked': {
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#FFF',
          borderBottom: 0,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(3n+1)': {
            backgroundColor: '#36404F',
          },
          '&:nth-of-type(3n+2)': {
            backgroundColor: '#2C3440',
          },
          '&:nth-of-type(3n+3)': {
            backgroundColor: '#222831',
          },
          '& .MuiTableCell-head': {
            backgroundColor: '#0C1C31',
            color: '#C7D5E8',
            fontSize: 14,
            fontWeight: '600',
            borderBottom: '1px solid #059ED9',
          },
          '&:hover': {
            boxShadow: 'inset 3px 0px 0px 0px #059ED9',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: 0,
          '& .MuiOutlinedInput-root': {
            padding: 0,
          },
          '& .MuiAutocomplete-clearIndicator': {
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#18202A',
          border: '1px solid #434E61',
          borderRadius: '0.25rem',
          color: '#FFFFFF',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          background: '#FFFFFF',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '& .internal': {
            '& .MuiDivider-root': {
              background: 'none',
              '&::after, &::before': {
                borderTop: 'medium dashed #059ED9'
              }
            }
          },
          '& .external': {
            '& .MuiDivider-root': {
              background: 'none',
              '&::after, &::before': {
                borderTop: 'medium dashed #7ECDEA'
              }
            }
          }
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '34px',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          color: '#FFFFFF',
        },
        selectIcon: {
          color: '#FFFFFF',
        },
        displayedRows: {
          color: '#FFFFFF',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiTab-root': {
            textTransform: 'inherit',
            fontSize: 16,
          },
          '& .MuiTabs-scroller': {
            boxShadow: 'inset 2px -4px 0px -2px #566378',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          display: 'block',
          width: '100%',
          '& .MuiTab-root': {
            width: '16.7%',
            textTransform: 'inherit',
            maxWidth: 'inherit'
          }
        },
      },
    }
  },
})

export default theme
