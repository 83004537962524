import { Models } from '@rematch/core'
import dashboard from './private/dashboard'
import dashboardClient from './private/dashboardClient'
import commonActions from './private/commonActions'
import auth from './public/auth'
import loader from './public/loader'
import popupModel from './public/popupModel'

export interface RootModel extends Models<RootModel> {
  auth: typeof auth
  popupModel: typeof popupModel
  loader: typeof loader
  dashboard: typeof dashboard
  dashboardClient: typeof dashboardClient
  commonActions: typeof commonActions
}

export const models: RootModel = { auth, popupModel, loader, dashboard, commonActions, dashboardClient }
