export enum UserRole {
  analyst = 'analyst',
  qa = 'qa',
  client = 'client',
}

export const RoleMapping: any = {
  qa: 'QA',
  client: 'Client',
  analyst: 'Analyst',
}
