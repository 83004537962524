import { useAccount, useIsAuthenticated } from '@azure/msal-react'
import { ReactElement, useMemo } from 'react'
import { Navigate } from 'react-router-dom'

interface PrivateRouteProps {
  roles: string[]
  children: ReactElement
}

const PrivateRoute = ({ roles, children }: PrivateRouteProps) => {
  const account = useAccount()
  const isAuthenticated = useIsAuthenticated()
  const userJobTitle = useMemo(() => {
    return account?.idTokenClaims?.jobTitle || ''
  }, [account])

  const hasAccess = roles.some((role) => role === userJobTitle)
  if (!hasAccess) {
    return <Navigate to='/login' replace />
  }
  if (!account || !account.username) {
    return <Navigate to='*' replace />
  }
  if (!isAuthenticated) {
    return <Navigate to='/login' replace />
  }

  return <>{children}</>
}

export default PrivateRoute
