import { createModel } from '@rematch/core'
import { RootModel } from '..'
import { httpService } from '../../../Config/httpService'

interface AuthState {
  isAuthenticated: boolean
  user: any
}

const auth = createModel<RootModel>()({
  state: {
    isAuthenticated: false,
    user: {},
  } as AuthState,
  reducers: {
    setIsAuthenticated(state, payload: boolean) {
      return { ...state, isAuthenticated: payload }
    },
    setUser(state, payload) {
      return { ...state, user: payload }
    },
  },
  effects: (dispatch) => ({
    async fetchUser() {
      try {
        dispatch.loader.setIsLoading(true)
        const response = await httpService.get('/user/me')
        if (response.statusText === 'OK') {
          dispatch.auth.setUser(response.data)
        }
        dispatch.loader.setIsLoading(false)
      } catch (e) {
        dispatch.loader.setIsLoading(false)
        console.error(`logout error : ${e}`)
      }
    },
  }),
})

export default auth
