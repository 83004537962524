import { createModel } from '@rematch/core'
import { RootModel } from '..'
import { httpService } from '../../../Config/httpService'
import { CLIENT_TICKET_TAB } from '../../../Constants/api-constants'

type IDashboardClientState = {
  openTickets: {
    data: Array<IQaResponse>
    pagination: IPagination
  }
  resolvedTickets: {
    data: Array<IQaResponse>
    pagination: IPagination
  }
}

const dashboardClient = createModel<RootModel>()({
  state: {
    openTickets: {},
    resolvedTickets: {},
  } as IDashboardClientState,
  reducers: {
    setOpenTickets(state, payload) {
      return { ...state, openTickets: payload }
    },
    setResolvedTickets(state, payload) {
      return { ...state, resolvedTickets: payload }
    },
  },
  effects: (dispatch) => ({
    async fetchOpenTickets(payload) {
      try {
        dispatch.loader.setIsLoading(true)
        const response = await httpService.post(`/ticket/client/list/${CLIENT_TICKET_TAB.OPENED}`, { ...payload })
        if (response.data?.data) {
          dispatch.dashboardClient.setOpenTickets(response.data)
        }
        dispatch.loader.setIsLoading(false)
      } catch (error) {
        dispatch.loader.setIsLoading(false)
      }
    },
    async fetchResolvedTickets(payload) {
      try {
        dispatch.loader.setIsLoading(true)
        const response = await httpService.post(`/ticket/client/list/${CLIENT_TICKET_TAB.RESOLVED}`, { ...payload })
        if (response.data.data) {
          dispatch.dashboardClient.setResolvedTickets(response.data)
        }
        dispatch.loader.setIsLoading(false)
      } catch (error) {
        dispatch.loader.setIsLoading(false)
        dispatch.dashboard.setDraftTickets({})
      }
    },
  }),
})

export default dashboardClient
