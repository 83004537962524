import { createModel } from '@rematch/core'
import { RootModel } from '..'
import { httpService } from '../../../Config/httpService'
import { TICKET_TABS } from '../../../Constants/api-constants'

type IDashboardState = {
  unAssignedTickets: {
    data: Array<IQaResponse>
    pagination: IPagination
  }
  draftTickets: {
    data: Array<IQaResponse>
    pagination: IPagination
  }
  qaReviewInternalTickets: {
    data: Array<IQaResponse>
    pagination: IPagination
  }
  inProgressTickets: {
    data: Array<IQaResponse>
    pagination: IPagination
  }
  qaReviewTickets: {
    data: Array<IQaResponse>
    pagination: IPagination
  }
  closedTickets: {
    data: Array<IQaResponse>
    pagination: IPagination
  }
  analytics: {
    unassignTickets: number
    myTickets: number
    qaReviewTickets: number
  }
  ticket: ITicket
  isProceed: boolean
  selAssignee: string
}

const dashboard = createModel<RootModel>()({
  state: {
    unAssignedTickets: {},
    draftTickets: {},
    qaReviewInternalTickets: {},
    inProgressTickets: {},
    qaReviewTickets: {},
    closedTickets: {},
    analytics: {},
    ticket: {},
    isProceed: false,
    selAssignee: '',
  } as IDashboardState,
  reducers: {
    setDraftTickets(state, payload) {
      return { ...state, draftTickets: payload }
    },
    setUnAssignedTickets(state, payload) {
      return { ...state, unAssignedTickets: payload }
    },
    setQaReviewInternalTickets(state, payload) {
      return { ...state, qaReviewInternalTickets: payload }
    },
    setInProgressTickets(state, payload) {
      return { ...state, inProgressTickets: payload }
    },
    setQaReviewTickets(state, payload) {
      return { ...state, qaReviewTickets: payload }
    },
    setClosedTickets(state, payload) {
      return { ...state, closedTickets: payload }
    },
    setAnalytics(state, payload) {
      return { ...state, analytics: payload }
    },
    setTicket(state, payload: ITicket) {
      const isProceed = Object.values(payload).every((i) => !!i)
      return { ...state, ticket: payload, isProceed }
    },
    setSelAssignee(state, payload) {
      return { ...state, selAssignee: payload }
    },
  },
  effects: (dispatch) => ({
    async fetchUnAssignedTickets(payload) {
      try {
        dispatch.loader.setIsLoading(true)
        const response = await httpService.post(`/ticket/list/${TICKET_TABS.UN_ASSIGNED}`, { ...payload })
        if (response.data?.data) {
          dispatch.dashboard.setUnAssignedTickets(response.data)
        }
        dispatch.loader.setIsLoading(false)
      } catch (error) {
        dispatch.loader.setIsLoading(false)
      }
    },
    async fetchDraftTickets(payload) {
      try {
        dispatch.loader.setIsLoading(true)
        const response = await httpService.post(`/ticket/list/${TICKET_TABS.DRAFT}`, { ...payload })
        if (response.data.data) {
          dispatch.dashboard.setDraftTickets(response.data)
        }
        dispatch.loader.setIsLoading(false)
      } catch (error) {
        dispatch.loader.setIsLoading(false)
        dispatch.dashboard.setDraftTickets({})
      }
    },
    async fetchQaReviewInternalTickets(payload) {
      try {
        dispatch.loader.setIsLoading(true)
        const response = await httpService.post(`/ticket/list/${TICKET_TABS.QA_INTERNAL_REVIEW}`, { ...payload }) 
        if (response.data.data) {
          dispatch.dashboard.setQaReviewInternalTickets(response.data)
        }
        dispatch.loader.setIsLoading(false)
      } catch (error) {
        dispatch.loader.setIsLoading(false)
        dispatch.dashboard.setQaReviewInternalTickets({})
      }
    },
    async fetchInProgressTickets(payload) {
      try {
        dispatch.loader.setIsLoading(true)
        const response = await httpService.post(`/ticket/list/${TICKET_TABS.IN_PROGRESS}`, { ...payload })
        if (response.data) {
          dispatch.dashboard.setInProgressTickets(response.data)
        }
        dispatch.loader.setIsLoading(false)
      } catch (error) {
        dispatch.loader.setIsLoading(false)
        dispatch.dashboard.setInProgressTickets({})
      }
    },
    async fetchQaReviewTickets(payload) {
      try {
        dispatch.loader.setIsLoading(true)
        const response = await httpService.post(`/ticket/list/${TICKET_TABS.QA_REVIEW}`, { ...payload })
        if (response.data) {
          dispatch.dashboard.setQaReviewTickets(response.data)
        }
        dispatch.loader.setIsLoading(false)
      } catch (error) {
        dispatch.loader.setIsLoading(false)
        dispatch.dashboard.setQaReviewTickets({})
      }
    },
    async fetchClosedTickets(payload) {
      try {
        dispatch.loader.setIsLoading(true)
        const response = await httpService.post(`/ticket/list/${TICKET_TABS.CLOSED}`, { ...payload })
        if (response.data) {
          dispatch.dashboard.setClosedTickets(response.data)
        }
        dispatch.loader.setIsLoading(false)
      } catch (error) {
        dispatch.loader.setIsLoading(false)
        dispatch.dashboard.setClosedTickets({})
      }
    },
    async fetchAnalytics() {
      try {
        dispatch.loader.setIsLoading(true)
        const response = await httpService.get('/ticket/analytics')
        if (response.data) {
          dispatch.dashboard.setAnalytics(response.data)
        }
        dispatch.loader.setIsLoading(false)
      } catch (error) {
        dispatch.loader.setIsLoading(false)
        dispatch.dashboard.setAnalytics({})
      }
    },
    async updateTicket({ ticketId, evidence }, state) {
      try {
        dispatch.loader.setIsLoading(true)
        await httpService.put(`/ticket/${ticketId}`, {
          riskLevel: state.dashboard.ticket.riskLevel,
          title: state.dashboard.ticket.title.trim(),
          description: state.dashboard.ticket.description.trim(),
          assets: state.dashboard.ticket.assets.split(',').map((i) => i.trim()),
          evidence,
        })
      } catch (e) {
        console.error(`createGroupTicket : ${e}`)
      } finally {
        dispatch.loader.setIsLoading(false)
      }
    },
    async createGroupTicket({ evidence }, state) {
      try {
        dispatch.loader.setIsLoading(true)
        await httpService.post('/ticket/new', {
          riskLevel: state.dashboard.ticket.riskLevel,
          title: state.dashboard.ticket.title.trim(),
          description: state.dashboard.ticket.description.trim(),
          assets: state.dashboard.ticket.assets.split(',').map((i) => i.trim()),
          evidence,
          source: state.dashboard.ticket.source,
          companyId: state.dashboard.ticket.companyId,
        })
      } catch (e) {
        console.error(`createGroupTicket : ${e}`)
      } finally {
        dispatch.loader.setIsLoading(false)
      }
    },
  }),
})

export default dashboard
