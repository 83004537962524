import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { UserRole } from '../Constants/roles'
import Layout from '../Layouts/Default'
import PrivateRoute from './PrivateRoute'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import Loading from '../Shared/Loading'

// const AnalystDashboard = lazy(() => import('../Pages/AnalystDashboard'))
const QADashboard = lazy(() => import('../Pages/QADashboard'))
const ClientDashboard = lazy(() => import('../Pages/ClientDashboard'))
const NotFound = lazy(() => import('../Pages/NotFound'))
const Login = lazy(() => import('../Pages/LoginPage'))

const AppRoutes = () => {
  const { inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  if (inProgress === InteractionStatus.HandleRedirect) {
    return <Loading />
  }
  return (
    <Routes>
      {isAuthenticated && (
        <Route path='/' element={<Layout />}>
          <Route
            path='analyst/*'
            element={
              <PrivateRoute roles={[UserRole.analyst]}>
                <QADashboard />
              </PrivateRoute>
            }
          />
          <Route
            path='/client/*'
            element={
              <PrivateRoute roles={[UserRole.client]}>
                <ClientDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path='/qa/*'
            element={
              <PrivateRoute roles={[UserRole.qa]}>
                <QADashboard />
              </PrivateRoute>
            }
          />
        </Route>
      )}
      <Route path='/login' element={<Login />} />
      <Route path='/' element={<Login />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}
export default AppRoutes
