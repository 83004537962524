const clientId = process.env.REACT_APP_CLIENT_ID || ''
const tenantId = process.env.REACT_APP_TENANT_ID || ''
const redirectUri = window.location.origin

export const config = {
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri,
    postLogoutRedirectUri: redirectUri,
  },
}

export const tokenConfig = {
  scopes: ['openid', 'User.read'],
}
