import styled from '@emotion/styled'
import { Button, Dialog, DialogActions, DialogContent, DialogContentProps, DialogContentText, DialogTitle, useTheme } from '@mui/material'
import { isEmpty, uniqueId } from 'lodash'

type modalProps = {
  isOpen: boolean
  title: string
  actions: Array<any>
  onClose: (event: object, reason: 'backdropClick' | 'escapeKeyDown') => void
  children: React.ReactElement
}

const StyledDialogContent = styled(DialogContent)<DialogContentProps>(() => ({
  minWidth: 250,
  dividers: true,
}))

const DialogBox = ({ title, actions, isOpen, onClose, children }: modalProps) => {
  const theme = useTheme()
  return (
    <Dialog
      scroll='paper'
      maxWidth='md'
      open={isOpen}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      PaperProps={{ elevation: 2, sx: { background: theme.palette.background.tab } }}
    >
      <DialogTitle id='alert-dialog-title' color={theme.palette.text.default}>
        {title}
      </DialogTitle>
      <StyledDialogContent>
        <DialogContentText id='alert-dialog-description' color={theme.palette.text.default}>
          {children}
        </DialogContentText>
      </StyledDialogContent>
      {!isEmpty(actions) && (
        <DialogActions>
          {actions.map((actionItem) => (
            <Button key={uniqueId('modal-')} color='secondary' variant={actionItem.variant} onClick={actionItem.actionHandler}>
              {actionItem.label}
            </Button>
          ))}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default DialogBox
