import { createModel } from '@rematch/core'
import { RootModel } from '../index'

type initialStateType = {
  isLoading: boolean
}

const loader = createModel<RootModel>()({
  state: {
    isLoading: false,
  } as initialStateType,
  reducers: {
    setIsLoading(state, payload) {
      return { ...state, isLoading: payload }
    },
  },
})

export default loader
