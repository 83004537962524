import { InteractionRequiredAuthError } from '@azure/msal-browser'
import axios, { AxiosInstance } from 'axios'
import { msalInstance } from '../'
import { tokenConfig } from './msalConfig'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const httpService: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

let isTokenRefreshing = false
let refreshSubscribers: ((accessToken: string) => void)[] = []

const onTokenRefreshed = (accessToken: string) => {
  refreshSubscribers.forEach((callback) => callback(accessToken))
  refreshSubscribers = []
}

const addRefreshSubscriber = (callback: (accessToken: string) => void) => {
  refreshSubscribers.push(callback)
}
// create a closure to access token from auth model
// let authToken: string | undefined = localStorage.getItem('TOKEN')
const setAuthToken = (token: string | undefined) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

// add request interceptor to set Authorization header
httpService.interceptors.request.use(
  async (config: any) => {
    try {
      // const authToken = localStorage.getItem('TOKEN')
      const response = await msalInstance.acquireTokenSilent(tokenConfig)
      if (response.idToken) {
        config.headers.Authorization = `Bearer ${response.idToken}`
      } else {
        delete config.headers?.Authorization
      }
      return config
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        console.log('[InteractionRequiredAuthError] : Redirecting for Token', error.message)
        await msalInstance.acquireTokenRedirect(tokenConfig)
      }
      throw error
    }
  },
  (error) => Promise.reject(error),
)

httpService.interceptors.response.use(
  (response) => response,
  (error: any) => {
    if (error.response && error.response.status === 401) {
      const originalRequest = error.config
      if (!isTokenRefreshing) {
        isTokenRefreshing = true
        msalInstance
          .acquireTokenSilent(tokenConfig)
          .then((response) => {
            const accessToken = response.idToken
            onTokenRefreshed(accessToken)
            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`
            return axios(originalRequest)
          })
          .catch((error) => {
            // Handle token refresh error
            console.error('Token refresh failed:', error)
            // Redirect to login or perform other error handling
            msalInstance.logoutRedirect()
          })
          .finally(() => {
            isTokenRefreshing = false
          })
      }
      return new Promise((resolve) => {
        addRefreshSubscriber((accessToken: string) => {
          originalRequest.headers['Authorization'] = `Bearer ${accessToken}`
          resolve(axios(originalRequest))
        })
      })
    }
    return Promise.reject(error)
  },
)

export { httpService, setAuthToken }
