import { createModel } from '@rematch/core'
import { RootModel } from '../index'

type initialStateType = {
  title: string
  message: any
  isOpen: boolean
  actionButtons: Array<any>
}

const popupModel = createModel<RootModel>()({
  state: {
    title: '',
    message: '',
    isOpen: false,
    actionButtons: [],
  } as initialStateType,
  reducers: {
    setModalOpen(state, payload) {
      const { title, message, actionButtons } = payload
      return { ...state, isOpen: true, title, message, actionButtons }
    },
    setModalClose(state) {
      return { ...state, isOpen: false }
    },
    setModalOptions(state, payload) {
      return { ...state, ...payload }
    },
  },
})

export default popupModel
