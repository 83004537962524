export enum TICKET_TABS {
  UN_ASSIGNED = 'un_assigned',
  DRAFT = 'draft',
	QA_INTERNAL_REVIEW = 'qa_interval_review',
  IN_PROGRESS = 'in_progress',
  QA_REVIEW = 'qa_review',
  CLOSED = 'closed',
}

export enum CLIENT_TICKET_TAB {
  OPENED = 'opened',
  RESOLVED = 'resolved',
}

export enum DATE_FORMAT {
  FE_FORMAT = 'DD-MM-YYYY',
  BE_FORMAT = 'YYYY-MM-DD',
}
