import { init, RematchRootState, RematchDispatch } from '@rematch/core'
import { models, RootModel } from './Models'

export const store = init<RootModel>({
  models,
})

export type Store = typeof store
export type RootState = RematchRootState<RootModel>
export type Dispatch = RematchDispatch<RootModel>
