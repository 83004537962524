export const QA_HEADER_MENU = [
  {
    key: 'threat_intelligence',
    label: 'Threat Intelligence',
    href: '',
  },
]

export const ANALYST_HEADER_MENU = [
  {
    key: 'threat_intelligence',
    label: 'Threat Intelligence',
    href: '',
  },
]

export const CLIENT_HEADER_MENU = [
  {
    key: 'threat_intelligence',
    label: 'Threat Intelligence',
    href: '',
  },
]
