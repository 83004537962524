import { createModel } from '@rematch/core'
import { RootModel } from '..'
import { httpService } from '../../../Config/httpService'
import { UserRole } from '../../../Constants/roles'

type CommonActionsState = {
  selTicket: IQaResponse
  filterOptions: {
    riskLevels?: Array<any>
    companies?: Array<any>
    assignees?: {
      analysts: Array<any>
      qas: Array<any>
    }
    clients?: Array<any>
  }
}

const commonActions = createModel<RootModel>()({
  state: {
    selTicket: {},
    filterOptions: {},
  } as CommonActionsState,
  reducers: {
    setSelTicket(state, payload) {
      return { ...state, selTicket: payload }
    },
    setFilterOptions(state, payload) {
      return { ...state, filterOptions: payload }
    },
  },
  effects: (dispatch) => ({
    async fetchMasterFilterData(_, state) {
      try {
        dispatch.loader.setIsLoading(true)
        const response = await httpService.get('/ticket/master/data')
        if (response.data) {
          let filters = response.data
          if (state.auth.user.role !== UserRole.client)
            filters = {
              ...filters,
              assignees: {
                ...filters.assignees,
                analysts: [{ label: `${state.auth.user.name} (You)`, value: state.auth.user.id }, ...(filters.assignees?.analysts || [])],
              },
            }
          dispatch.commonActions.setFilterOptions(filters)
        }
        dispatch.loader.setIsLoading(false)
      } catch (error) {
        dispatch.loader.setIsLoading(false)
        dispatch.commonActions.setFilterOptions([])
      }
    },
    async assignTicket(payload) {
      const { ticketId, assigneeId } = payload
      try {
        dispatch.loader.setIsLoading(true)
        await httpService.patch(`/ticket/${ticketId}/assign`, { assigneeId })
      } catch (e) {
        console.error(`assignTicket error : ${e}`)
      } finally {
        dispatch.loader.setIsLoading(false)
      }
    },
    async unassignTicket(ticketId: string) {
      try {
        dispatch.loader.setIsLoading(true)
        await httpService.patch(`/ticket/${ticketId}/unassign`)
      } catch (e) {
        console.error(`unassignTicket error : ${e}`)
      } finally {
        dispatch.loader.setIsLoading(false)
      }
    },
    async fetchTicketDetails(id: string) {
      try {
        dispatch.loader.setIsLoading(true)
        const response = await httpService.get(`/ticket/${id}/details`)
        dispatch.commonActions.setSelTicket(response.data)
      } catch (e) {
        console.error(`fetchTicketDetails error : ${e}`)
      } finally {
        dispatch.loader.setIsLoading(false)
      }
    },
    async postRemark({ ticketId, type, content }) {
      try {
        dispatch.loader.setIsLoading(true)
        await httpService.post(`/ticket/${ticketId}/remark`, {
          type,
          content,
        })
      } catch (e) {
        console.error(`fetchTicketDetails error : ${e}`)
      } finally {
        dispatch.loader.setIsLoading(false)
      }
    },
    async archive(ticketId: string) {
      try {
        dispatch.loader.setIsLoading(true)
        await httpService.patch(`/ticket/${ticketId}/archive`)
      } catch (e) {
        console.error(`qaClose error : ${e}`)
      } finally {
        dispatch.loader.setIsLoading(false)
      }
    },
    async unArchive(ticketId: string) {
      try {
        dispatch.loader.setIsLoading(true)
        await httpService.patch(`/ticket/${ticketId}/unarchive`)
      } catch (e) {
        console.error(`qaClose error : ${e}`)
      } finally {
        dispatch.loader.setIsLoading(false)
      }
    },
    async sendToQA(ticketId: string) {
      try {
        dispatch.loader.setIsLoading(true)
        await httpService.patch(`/ticket/${ticketId}/send_to_qa`)
      } catch (e) {
        console.error(`sendToQA error : ${e}`)
      } finally {
        dispatch.loader.setIsLoading(false)
      }
    },
    async sendToClient(ticketId: string) {
      try {
        dispatch.loader.setIsLoading(true)
        await httpService.patch(`/ticket/${ticketId}/send_to_client`)
      } catch (e) {
        console.error(`sendToClient error : ${e}`)
      } finally {
        dispatch.loader.setIsLoading(false)
      }
    },
    async sendToQAReview(ticketId: string) {
      try {
        dispatch.loader.setIsLoading(true)
        await httpService.patch(`/ticket/${ticketId}/qa_review`)
      } catch (e) {
        console.error(`sendToQAReview error : ${e}`)
      } finally {
        dispatch.loader.setIsLoading(false)
      }
    },
    async qaClose(ticketId: string) {
      try {
        dispatch.loader.setIsLoading(true)
        await httpService.patch(`/ticket/${ticketId}/qa_close`)
      } catch (e) {
        console.error(`qaClose error : ${e}`)
      } finally {
        dispatch.loader.setIsLoading(false)
      }
    },
    async clientAccept(ticketId: string) {
      try {
        dispatch.loader.setIsLoading(true)
        await httpService.patch(`/ticket/${ticketId}/client_accept`)
      } catch (e) {
        console.error(`qaClose error : ${e}`)
      } finally {
        dispatch.loader.setIsLoading(false)
      }
    },
    async clientOnHold(ticketId: string) {
      try {
        dispatch.loader.setIsLoading(true)
        await httpService.patch(`/ticket/${ticketId}/client_on_hold`)
      } catch (e) {
        console.error(`qaClose error : ${e}`)
      } finally {
        dispatch.loader.setIsLoading(false)
      }
    },
    async clientResolve(ticketId: string) {
      try {
        dispatch.loader.setIsLoading(true)
        await httpService.patch(`/ticket/${ticketId}/client_resolve`)
      } catch (e) {
        console.error(`qaClose error : ${e}`)
      } finally {
        dispatch.loader.setIsLoading(false)
      }
    },
    async revertToDraft(ticketId: string) {
      try {
        dispatch.loader.setIsLoading(true)
        await httpService.patch(`/ticket/${ticketId}/revert-to-draft`)
      } catch (e) {
        console.error(`revert to draft error : ${e}`)
      } finally {
        dispatch.loader.setIsLoading(false)
      }
    },
  }),
})

export default commonActions
