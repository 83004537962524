import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import AppRoutes from './Routes'
import DialogBox from './Shared/DialogBox'
import Loading from './Shared/Loading'
import { Dispatch, RootState } from './Store'
import { MsalProvider } from '@azure/msal-react'
import { IPublicClientApplication } from '@azure/msal-browser'
import { Suspense } from 'react'

type AppProps = {
  pca: IPublicClientApplication
}

function App({ pca }: AppProps) {
  const dispatch = useDispatch<Dispatch>()
  const popupModel = useSelector((state: RootState) => state.popupModel)
  const { isLoading } = useSelector((state: RootState) => state.loader)
  return (
    <MsalProvider instance={pca}>
      <Suspense fallback={<Loading />}>
        <Box>
          <DialogBox
            title={popupModel.title}
            isOpen={popupModel.isOpen}
            onClose={() => dispatch.popupModel.setModalClose()}
            actions={popupModel.actionButtons}
          >
            {popupModel.message}
          </DialogBox>
          {isLoading && <Loading />}
          <AppRoutes />
        </Box>
      </Suspense>
    </MsalProvider>
  )
}

export default App
