import { CircularProgress } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'

const Loading = () => {
  return (
    <Backdrop open sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress className='circleProgress' />
    </Backdrop>
  )
}

export default Loading
