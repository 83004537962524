import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import Header from '../Shared/AppBar'
import { useAccount, useMsal } from '@azure/msal-react'

function Layout() {
  const account = useAccount()
  const { instance } = useMsal()
  return (
    <Box sx={{ background: '#0C233C' }}>
      <Header user={account || {}} OnLogout={() => instance.logout()} />
      <Outlet />
    </Box>
  )
}

export default Layout
