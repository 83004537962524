import MenuIcon from '@mui/icons-material/Menu'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Badge, Button } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { ANALYST_HEADER_MENU, CLIENT_HEADER_MENU, QA_HEADER_MENU } from '../../Constants/menuItems'
import { UserRole } from '../../Constants/roles'
import logo from './../../assets/images/kpmg-logo.png'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'

function Header({ user, OnLogout }: IAppBar) {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const accountRole = useMemo(() => {
    const role = String(user.idTokenClaims?.jobTitle || '')
    return role
  }, [])
  const userName = useMemo(() => {
    const name = String(user.name || user.username).slice(0, 2)
    return name
  }, [])
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }
  const handleLogout = () => {
    setAnchorElUser(null)
    localStorage.clear()
    sessionStorage.clear()
    OnLogout()
  }
  return (
    <AppBar position='static' sx={{ background: '#16264C' }}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <img src={logo} alt='Logo' />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {accountRole === UserRole.qa &&
                QA_HEADER_MENU.map((item) => (
                  <MenuItem key={item.key} onClick={handleCloseNavMenu}>
                    <Typography textAlign='center' variant='subtitle2'>
                      {item.label}
                    </Typography>
                  </MenuItem>
                ))}
              {accountRole === UserRole.analyst &&
                ANALYST_HEADER_MENU.map((item) => (
                  <MenuItem key={item.key} onClick={handleCloseNavMenu}>
                    <Typography textAlign='center' variant='subtitle2'>
                      {item.label}
                    </Typography>
                  </MenuItem>
                ))}
              {accountRole === UserRole.client &&
                CLIENT_HEADER_MENU.map((item) => (
                  <MenuItem key={item.key} onClick={handleCloseNavMenu}>
                    <Typography textAlign='center' variant='subtitle2'>
                      {item.label}
                    </Typography>
                  </MenuItem>
                ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {accountRole === UserRole.qa &&
              QA_HEADER_MENU.map((item) => (
                <Button LinkComponent={Link} href={item.href} key={item.key} onClick={handleCloseNavMenu}>
                  <Typography textAlign='center' variant='subtitle2'>
                    {item.label}
                  </Typography>
                </Button>
              ))}
            {accountRole === UserRole.analyst &&
              ANALYST_HEADER_MENU.map((item) => (
                <Button key={item.key} LinkComponent={Link} href={item.href} onClick={handleCloseNavMenu}>
                  <Typography textAlign='center' variant='subtitle2'>
                    {item.label}
                  </Typography>
                </Button>
              ))}
            {accountRole === UserRole.client &&
              CLIENT_HEADER_MENU.map((item) => (
                <Button key={item.key} LinkComponent={Link} href={item.href} onClick={handleCloseNavMenu}>
                  <Typography textAlign='center' variant='subtitle2'>
                    {item.label}
                  </Typography>
                </Button>
              ))}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          {/** Render this company logo in case of CLIENT. */}
          {/* <Box>{accountRole === UserRole.client && <img src={logo} alt='Logo' />}</Box> */}
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Box sx={{ p: 1 }}>
              <IconButton size='large' aria-label='show 9 new notifications' color='inherit'>
                <Badge badgeContent={1} color='error' variant='dot'>
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Box>
            <Tooltip title='Open Profile'>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar src={user.profile}> {userName.toUpperCase()} </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Menu
              slotProps={{ paper: { sx: { background: 'none' } } }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              MenuListProps={{ sx: { padding: 0 } }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Button onClick={handleLogout} variant='contained' sx={{ background: '#333333' }}>
                <LogoutOutlinedIcon sx={{ color: 'white', mr: 1 }} />
                <Typography textAlign='center'>Logout</Typography>
              </Button>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Header
